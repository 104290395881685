// Packages
import React, { useContext } from 'react';
import get from 'lodash/get';
import { useNavigate } from 'react-router-dom';

// Relatives
import UserContext from '../../contexts/UserContext/UserContext';
import Layout from '../../layouts/Layout';
// import Organisation from '../Login/steps/Organisation';
import PersonalitiesRegister from '../Login/steps/Personalities/PersonalitiesRegister';

const NgoUpdate = () => {
  const { userNGOSelected } = useContext(UserContext);
  const id = get(userNGOSelected, 'id');
  const navigate = useNavigate();
  const ngoName = get(userNGOSelected, 'virtual_artist_handle', 'NGO');

  const handleSubmit = data => {
    if (!data) {
      return;
    }

    navigate('/');
  };

  const handleCancel = () => {
    navigate('/');
  };

  return (
    <Layout title={`Update ${ngoName}`} breadcrumb={['Home', `Update ${ngoName}`]}>
      <div className="ngo-update" key="ngo">
        <PersonalitiesRegister onSubmit={handleSubmit} onCancel={handleCancel} id={id} updateMode />
      </div>
    </Layout>
  );
};

NgoUpdate.defaultProps = {};

NgoUpdate.propTypes = {};

export default NgoUpdate;
