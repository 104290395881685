// Packages
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { IconButton, Menu, MenuItem /* , Badge */ } from '@mui/material';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import { /* AccountCircle, */ Logout } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
/* import Divider from '@mui/material/Divider';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AddReactionIcon from '@mui/icons-material/AddReaction';
 */

// Relatives
import UserContext from '../../../contexts/UserContext';

const ProfileMenu = props => {
  const { onClose, isMobile, parentRef, isOpen /* onSelectNGO, ngos */ } = props;
  const { logout } = useContext(UserContext);
  const navigate = useNavigate();

  const handleClickUpdateUserProfile = () => {
    navigate('/user/profile/update');
  };

  const handleClickUpdateNGO = () => {
    navigate('/personality/update');
  };

  /* const handleClickNGO = id => () => onSelectNGO(id);

  const handleClickClaimBroadcaster = () => {
    navigate('/joinrequest');
  }

  const handleClickPersonalities = () => {
    navigate('/register/personality');
  } */ 


  const MobileMenu = [
    // <MenuItem onClick={onClose} key="1">
    //   <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
    //     <Badge badgeContent={17} color="error">
    //       <NotificationsIcon />
    //     </Badge>
    //   </IconButton>
    //   <span>Notifications</span>
    // </MenuItem>,
    // <MenuItem onClick={onClose} key="2">
    //   <IconButton size="large" aria-label="show 4 new mails" color="inherit">
    //     <Badge badgeContent={4} color="error">
    //       <AccountCircle />
    //     </Badge>
    //   </IconButton>
    //   <span>Messages</span>
    // </MenuItem>,
    // <MenuItem onClick={onClose} key="3">
    //   <IconButton
    //     size="large"
    //     aria-label="account of current user"
    //     aria-controls="primary-search-account-menu"
    //     aria-haspopup="true"
    //     color="inherit"
    //   >
    //     <AccountCircle />
    //   </IconButton>
    //   <span>Profile</span>
    // </MenuItem>,
    /* <div key='list-profile-menu' className="list-profile-menu overflow-menu">
    {[...ngos].map(ngo => (
      <MenuItem onClick={handleClickNGO(ngo.id)} key={ngo.virtual_artist_handle}>
        <IconButton size="large" color="inherit">
          <SwapHorizIcon />
        </IconButton>
        <span className="handle-label">{ngo.virtual_artist_handle}</span>
      </MenuItem>
    ))}
    </div>, 
    <Divider key="divider-profile-menu" />,
    <MenuItem onClick={handleClickPersonalities} key="2">
      <IconButton size="large" color="inherit">
        <AdminPanelSettingsIcon />
      </IconButton>
      <div className="update-ngo-list-item">
        <span>Crate New Personality</span>
      </div>
    </MenuItem>,
    <MenuItem onClick={handleClickClaimBroadcaster} key="3">
      <IconButton size="large" color="inherit">
        <AddReactionIcon />
      </IconButton>
      <div className="update-ngo-list-item">
        <span>Join a New Broadcaster</span>
      </div>
    </MenuItem>,
     */
    <MenuItem onClick={handleClickUpdateNGO} key="4">
    <IconButton size="large" color="inherit">
      <EditIcon />
    </IconButton>
    <div className="update-ngo-list-item">
      <span>Update Personality Profile</span>
    </div>
  </MenuItem>,
  <MenuItem onClick={handleClickUpdateUserProfile} key="5">
      <IconButton size="large" color="inherit">
        <EditIcon />
      </IconButton>
      <div className="update-ngo-list-item">
        <span>Update User Profile</span>
      </div>
    </MenuItem>,
    <MenuItem onClick={logout} key="6">
      <IconButton size="large" color="inherit">
        <Logout />
      </IconButton>
      <span>Logout</span>
    </MenuItem>
  ];

  if (isMobile) {
    return MobileMenu;
  }

  return (
    <Menu
      className="layout-header-menu"
      anchorEl={parentRef.current}
      id="primary-search-account-menu-mobile"
      keepMounted
      open={isOpen}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <li className="menu-header text-center">
        <strong>User Options</strong>
      </li>
      {MobileMenu}
    </Menu>
  );
};

ProfileMenu.defaultProps = {
  parentRef: null,
  ngos: [],
  ngoSelected: null,
  isMobile: false,
  isOpen: false,
  onOpen: noop,
  onClose: noop,
  onSelectNGO: noop
};

ProfileMenu.propTypes = {
  parentRef: PropTypes.object,
  ngos: PropTypes.array,
  ngoSelected: PropTypes.object,
  isMobile: PropTypes.bool,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onSelectNGO: PropTypes.func
};

export default ProfileMenu;
