// Packages
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormHelperText, Grid, TextareaAutosize, TextField, Typography } from '@mui/material';
// import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

// Relatives
import MaskedInput from '../../../../components/MaskedInput';
import SelectNGO from '../../../../components/SelectNGO/SelectNGO';
import SelectBroadcaster from '../../../../components/SelectNGO/SelectBroadcaster';

const PersonalitiesStep1 = props => {
  const { editor } = props;
  const { control, register, setValue, getValues } = useFormContext();
  const defaultValueSelected = getValues('associationnames');
  const beneficiarySelected = getValues('beneficiary');
  const [backSelected, setBackSelected] = useState(defaultValueSelected);
  const [beneficiary, setBeneficiary] = useState(beneficiarySelected);
  const [selectName, setSelectedName] = useState(defaultValueSelected);


  useEffect(() => 
  {
   
    setValue('association', getValues('association'));
    setValue('beneficiary_non_profit_ngo_id', getValues('beneficiary_non_profit_ngo_id') !== 0 ? getValues('beneficiary_non_profit_ngo_id') : '');
    setBackSelected(!isEmpty(getValues('association')) ? getValues('association') : getValues('selected_broadcasters.0'));
    setBeneficiary(getValues('beneficiary'));
    

  }, [backSelected]);

  return (
    <>
      <Grid item xs={12}>
        <Controller
          name="virtual_artist_stage_name"
          control={control}
          rules={{ required: 'Personality Name is required' }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              label="Personality Name"
              variant="standard"
              helperText={
                fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )
              }
            />
          )}
        />
      </Grid>
      <Grid className={editor ? 'visually-hidden' : 'show'} item xs={12}>
        <Controller
          name={editor ? '' : 'virtual_artist_handle'}
          control={control}
          rules={{ required: editor ? false : 'Handle is required' }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              label="Handle"
              variant="standard"
              InputProps={{
                inputComponent: MaskedInput,
                inputProps: {
                  name: field.name,
                  mask: '@name',
                  blocks: {
                    name: {
                      mask: /^[0-9a-zA-Z_]{1,25}$/
                    }
                  }
                }
              }}
              helperText={
                fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="virtual_artist_bio"
          control={control}
          rules={{ required: 'Biography is required' }}
          render={({ field, fieldState }) => (
            <div className="text-area MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
              <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary">
                {' '}
                Biography
              </label>
              <TextareaAutosize {...field} maxLength={140} minRows={3} maxRows={6} label="About" variant="standard" />
              <FormHelperText>
                {fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )}
              </FormHelperText>
            </div>
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="virtual_artist_website"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              label="Website Url"
              variant="standard"
              helperText={
                fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )
              }
            />
          )}
        />
      </Grid>
      <Grid className={editor ? 'visually-hidden' : 'show'} item xs={12}>
              <label>Broadcasters</label>
              <SelectBroadcaster setNameProp={backSelected} data={data => {
                setValue('associationnames', data.newNgoName);
                setValue('selected_broadcasters', data.ngoId !== 0 ? data.ngoId : '');
                setSelectedName(data.newNgoName);
              }} />
              <TextField {...register("selected_broadcasters")} type='hidden' />
      </Grid>
      <Grid className={`${selectName !== '' && !editor ? 'show' : 'visually-hidden'}`} item xs={12}>
              <label>Beneficiary</label>
              <SelectNGO setNameProp={beneficiary} data={data => {
                setValue('beneficiary', data.newNgoName);
                setValue('beneficiary_non_profit_ngo_id', data.ngoId !== 0 ? data.ngoId : '');
              }} />
              <TextField {...register("associationnames")} type="hidden" />
              <TextField {...register("beneficiary_non_profit_ngo_id")} type='hidden' />
      </Grid>
    </>
  );
};

PersonalitiesStep1.defaultProps = {
  editor: false
};

PersonalitiesStep1.propTypes = {
  editor: PropTypes.bool
};

export default PersonalitiesStep1;
