// Packages
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router';

// Relatives
import Welcome from './pages/Welcome';
import Login from './pages/Login';
// import DashboardIndex from './pages/Dashboard/DashboardIndex';
/* import CampaignIndex from './pages/Campaigns/CampaignIndex';
import CampaignCreate from './pages/Campaigns/CampaignCreate';
import CampaignDetails from './pages/Campaigns/CampaignDetails';
import CampaignMoney from './pages/Campaigns/CampaignMoney';
import FundraiserIndex from './pages/Fundraiser/FundraiserIndex';
import FundraiserDetails from './pages/Fundraiser/FundraiserDetails';
import FundraiserMoney from './pages/Fundraiser/FundraiserMoney';
import FundraiserCreate from './pages/Fundraiser/FundraiserCreate';
import Personalities from './pages/Login/steps/Personalities'; */
import PersonalitiesRegister from './pages/Login/steps/Personalities/PersonalitiesRegister';
import ThankYouPage from './pages/ThankYouPage/ThankYouPage';
// import { DashboardComponent } from './templates/dashboard/DashboardComponent';
import ProfileUpdate from './pages/User/ProfileUpdate';
import NgoUpdate from './pages/Ngo/NgoUpdate';
import { theme } from './const/Theme';
import AppContext from './contexts/AppContext';
import UserProvider from './contexts/UserContext/UserProvider';
import UserContext from './contexts/UserContext/UserContext';
import { getApiHost, getHost, getEnvironment } from './config/settings';

// Styles
import './styles/theme.scss';
import './App.css';

const App = props => {
  const { sessionKey } = props;

  const environment = useMemo(() => getEnvironment(), []);

  const apiHost = getApiHost(environment);
  const host = getHost(environment);

  const appContextValue = useMemo(() => ({ host, apiHost }), [host, apiHost]);

  return (
    <AppContext.Provider value={appContextValue}>
      <UserProvider sessionKey={sessionKey}>
        <UserContext.Consumer>
          {context => {
            const { isLogged, status } = context;

            return (
              <ThemeProvider theme={theme}>
                <div className="App">
                  <Routes>
                    {/* isLogged && <Route exact path="/dashboard" element={<DashboardComponent />} /> */}
                    {/* isLogged && <Route exact path="/fundraisers/:id/funds" element={<FundraiserMoney />} /> */}
                    {/* isLogged && (
                      <Route exact path="/fundraisers/:id/update" element={<FundraiserCreate updateMode />} />
                    ) */}
                    {/* isLogged && <Route exact path="/fundraisers/create" element={<FundraiserCreate />} /> */}
                    {/* isLogged && <Route exact path="/fundraisers/:id" element={<FundraiserDetails />} /> */}
                    {/* isLogged && <Route exact path="/fundraisers" element={<FundraiserIndex />} /> */}
                    {/* isLogged && <Route exact path="/campaigns/:id/funds" element={<CampaignMoney />} /> */}
                    {/* isLogged && <Route exact path="/campaigns/:id/update" 
                    element={<CampaignCreate updateMode />} /> */}
                    {/* isLogged && <Route exact path="/campaigns/create" element={<CampaignCreate />} /> */}
                    {/* isLogged && <Route exact path="/campaigns/:id" element={<CampaignDetails />} /> */}
                    {/* isLogged && <Route exact path="/campaigns" element={<CampaignIndex />} /> */}
                    {isLogged && <Route exact path="/personality/update" element={<NgoUpdate />} />}
                    {isLogged && <Route exact path="/user/profile/update" element={<ProfileUpdate />} />}
                    {/* isLogged && <Route exact path="/joinrequest" element={<Personalities />} /> */}
                    {/* isLogged && <Route exact path="/joinnewrequest" element={<Personalities />} /> */}
                    {isLogged && <Route exact path="/register/personality" element={<PersonalitiesRegister />} />}
                    {!isLogged && <Route exact path="/register" element={<Login isRegister />} />}
                    {!isLogged && <Route exact path="/login" element={<Login />} />}
                    {status !== 'new-personality' && (
                      <Route exact path="/" element={!isLogged ? <Welcome /> : <ThankYouPage />} />
                    )}
                    {status === 'new-personality' && <Route path="*" element={<Navigate replace to="/register/personality" />} />}
                    <Route path="*" element={<Navigate replace to="/" />} />
                  </Routes>
                </div>
              </ThemeProvider>
            );
          }}
        </UserContext.Consumer>
      </UserProvider>
    </AppContext.Provider>
  );
};

App.defaultProps = {
  sessionKey: 'kaDa6ptP7Bk2xP3'
  // host: 'http://pj-jose-ai.ngrok.io'
};

App.propTypes = {
  sessionKey: PropTypes.string
};

export default App;
