/* eslint-disable no-unneeded-ternary */
import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Button } from '@mui/material';
import PropTypes  from 'prop-types';
import noop from 'lodash/noop';
import debounce from 'lodash/debounce';
import axios from 'axios';
import BackspaceIcon from '@mui/icons-material/Backspace';

import http from '../../services/api/http';
import UserContext from '../../contexts/UserContext/UserContext';
import AppContext from '../../contexts/AppContext';

const emptyString = '';

const SelectNGO = props => {
    // Base
    let cancelToken;
    const { data, setNameProp } = props;
    const { getUser } = useContext(UserContext);
    const user = getUser();
    const { token } = user;
    const { apiHost } = useContext(AppContext);
    // Requests
    const [ngoName, setNgoName] = useState('');
    const [virtualArtistId, setVirtualArtistId] = useState(0);
    const [virtualArtistUserId, setVirtualArtistUserId] = useState(0);
    const [listngos, setListNgos] = useState([]);
    const [expand, setExpand] = useState(false);

    console.log(setNameProp);

    const getNgoList = async (sort = 'id', find = '') => {
        
        
        if (typeof cancelToken !== typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.")
        }

        cancelToken = axios.CancelToken.source();

        try {

            const response = await http(apiHost, token.accessToken, token.tokenType)
            .get(`/api/web/v2/non-profit-ngos?sort=id${find !== '' ? `&${sort}=${find}` : ''}`, {
                cancelToken: cancelToken.token
            })
            setListNgos(response.data.output.data);
            
        } catch (error) {
            // console.log(error);
        }
        
    };

    const handleFindNGO = name => {
        getNgoList('name', name);
        setExpand(true);
    }

    const debounceHandleFindNGO = useCallback(debounce(handleFindNGO, 600), []);

    const handleSelectNGO = (virtualArtistId, virtualArtistUserId, newNgoName, ngoId) => {
        setNgoName(newNgoName);
        setVirtualArtistId(virtualArtistId);
        setVirtualArtistUserId(virtualArtistUserId);
        setExpand(false);
        data({ virtualArtistId, virtualArtistUserId, newNgoName, ngoId });
    }

    const handleClearInput = () => {
        getNgoList('name', emptyString);
        setNgoName(emptyString);
        data({ virtualArtistId: 0, virtualArtistUserId: 0, newNgoName: '', ngoId: 0 });
    }

    // run onload
    useEffect(() => {
        if(listngos.length <= 0){
            getNgoList();
        }
        if(setNameProp !== ''){
            setNgoName(setNameProp);
        }
    }, []);

    useEffect(() => {
        getNgoList();
    }, [virtualArtistId, virtualArtistUserId]);

    return (
        <div key='SelectNGO' className='SelectNGO w-100'>
            <input onClick={() => {
                setExpand(expand ? false : true);
            }} onChange={input => {
                // handleFindNGO(debounce(input.target.value, 600));
                debounceHandleFindNGO(input.target.value);
                setNgoName(input.target.value);
            }} key='SelectNGO_text-input' className='form-select' type='text' value={ngoName} placeholder='Pick an association' />
            <a onClick={() => {
                handleClearInput();
            }} className={`SelectNGO_btn-clear ${ngoName.length > 0 ? 'show' : 'visually-hidden'}`}>
                <BackspaceIcon />
            </a>
            <div key='SelectNGO_list' className={`SelectNGO__list ${expand ? 'show' : 'hidden'}`}>
                {listngos.map(NGO => (
                    <Button key={`SelectNGO_item_${NGO.virtual_artist_id}`} onClick={() => {
                        handleSelectNGO(NGO.virtual_artist_id, NGO.virtual_artist_user_id, NGO.name, NGO.id);
                    }}>
                        {NGO.name}
                    </Button>
                ))}
            </div>
        </div>
    );
}

SelectNGO.defaultProps = {
    data: noop,
    setNameProp: ''
}

SelectNGO.propTypes = {
    data: PropTypes.func,
    setNameProp: PropTypes.string
}

export default SelectNGO;