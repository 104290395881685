// Packages
import React, { useState, useMemo, useCallback, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import get from 'lodash/get';

// Relatives
import UserContext from './UserContext';
import { clearState, loadState, saveState } from '../../services/session/localStorage';
import AppContext from '../AppContext';
import http from '../../services/api/http';

const UserProvider = props => {
  const { children, sessionKey } = props;
  const { apiHost } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  // user variables
  const [id, setId] = useState(() => get(loadState(sessionKey), 'id'));
  const [token, setToken] = useState(get(loadState(sessionKey), 'token'));
  const [status, setStatus] = useState(get(loadState(sessionKey), 'status'));
  const [params, setParams] = useState(get(loadState(sessionKey), 'params'));
  // end user variables
  const [userNGOs, setUserNGOs] = useState([]);
  const [userNGOSelected, setUserNGOSelected] = useState(get(loadState(sessionKey), 'params.ngoSelected'));

  const setUser = useCallback(
    user => {
      const { id, token, status, params } = user;
      setId(id);
      setToken(token);
      setParams(params);
      setStatus(status);
      saveState(sessionKey, { id, token, status, params });
    },
    [setId, setToken, setStatus, setParams]
  );

  const setUserStatus = useCallback(
    status => {
      setStatus(status);
      saveState(sessionKey, { id, token, status, params });
    },
    [setStatus, id, token, params]
  );

  const setUserParams = useCallback(
    params => {
      setParams(state => {
        saveState(sessionKey, { id, token, status, params: { ...state, ...params } });

        return { ...state, ...params };
      });
    },
    [setParams, id, token, status]
  );

  const setUserNGO = useCallback(
    NGOSelected => {
      NGOSelected = parseInt(NGOSelected, 10);
      const selected = userNGOs.find(ngo => ngo.id === NGOSelected);
      if (selected) {
        setUserParams({ ngoSelected: selected });
        setUserNGOSelected(selected);
        navigate('/campaigns');
      }
    },
    [setUserNGOSelected, userNGOs]
  );

  const getUser = useCallback(() => {
    if (id && token) {
      return { id, token, status, params };
    }

    return null;
  }, [id, token, status, params]);

  const logout = useCallback(() => {
    clearState(sessionKey);
    setId(null);
    setToken(null);
    setStatus(null);
    setParams(null);
  }, [sessionKey]);

  const getNGOs = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/personalities?virtual_artist_user_id=${id}`)
      .catch(() => {
        logout();
        navigate('/');
      });

    setLoading(false);
    if (!response || !response.data) {
      return [];
    }

    const { output, success } = get(response, 'data');
    if (!success || !output) {
      return [];
    }

    setUserNGOs(output.data);
    if (!userNGOSelected) {
      const selected = get(output, 'data.0');
      setUserNGOSelected(selected);
      setUserParams({ ngoSelected: selected });
    }

    return output.data;
  };

  useEffect(() => {
    if (token) {
      getNGOs();
    } else {
      setLoading(false);
    }
  }, [token, status]);

  const refreshNGOs = useCallback(async () => {
    const ngos = await getNGOs();
    if (userNGOSelected) {
      const selected = ngos.find(ngo => ngo.id === userNGOSelected.id);
      setUserNGOSelected(selected);
      setUserParams({ ngoSelected: selected });
    }
  }, [userNGOSelected]);

  const isLogged = useMemo(() => !!id && !!token && status === 'active', [id, token, status]);

  const userValueMemo = useMemo(
    () => ({
      id,
      token,
      status,
      params,
      getUser,
      setUser,
      setUserStatus,
      setUserParams,
      setUserNGO,
      isLogged,
      logout,
      userNGOs,
      userNGOSelected,
      refreshNGOs
    }),
    [
      id,
      token,
      status,
      params,
      getUser,
      setUser,
      setUserStatus,
      setUserParams,
      setUserNGO,
      isLogged,
      logout,
      userNGOs,
      userNGOSelected,
      refreshNGOs
    ]
  );

  if (loading) {
    return null;
  }

  return <UserContext.Provider value={userValueMemo}>{children}</UserContext.Provider>;
};

UserProvider.defaultProps = {
  children: null,
  sessionKey: ''
};

UserProvider.propTypes = {
  children: PropTypes.node,
  sessionKey: PropTypes.string
};

export default UserProvider;
