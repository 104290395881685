import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Layout from '../../layouts/Layout';

const ThankYouPage = () => {

    return(
        <Layout>
            <div className='Thankyou-page'>
                <div className='Thankyou-page__body'>
                    <h1 className='Thankyou-page-icon'>
                        <CheckCircleIcon />
                    </h1>
                    <h4 className='Thankyou-page-message'>Your registration has been completed and will be reviewed and approved shortly.</h4>
                </div>
            </div>
        </Layout>
    )

}


export default ThankYouPage;