// Packages
import React, { useMemo } from 'react';
// import PropTypes from 'prop-types';
// import noop from 'lodash/noop';
import { MenuItem } from '@mui/material';
import { NavLink } from 'react-router-dom';

const NavigationMenu = () => {
  const navigation = useMemo(
    () => [
      /* { link: '/', name: 'Dashboard', soon: true },
      { link: '/campaigns', name: 'Campaigns' },
      { link: '/fundraisers', name: 'Fundraisers' },
      { link: '/schedule', name: 'Schedule', soon: true },
      { link: '/reports', name: 'Reports', soon: true } */
    ],
    []
  );

  return (
    <ul className="header-top-menu">
      {navigation.map((menuItem, index) => (
        <MenuItem key={`menu-item-${index}`} className={menuItem.soon ? 'disabled' : ''}>
          <NavLink to={menuItem.link} className={({ isActive }) => (isActive ? 'nav-link selected' : 'nav-link')}>
            <span className="menu-item__label">{menuItem.name}</span>
            {menuItem.soon && <span className="menu-item__soon-label">Coming Soon</span>}
          </NavLink>
        </MenuItem>
      ))}
    </ul>
  );
};

NavigationMenu.defaultProps = {};

NavigationMenu.propTypes = {};

export default NavigationMenu;
