// Packages
import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, Container, Typography, CircularProgress, Stepper, Step, StepButton, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import pick from 'lodash/pick';
import get from 'lodash/get';
import { useNavigate } from 'react-router-dom';

// Relatives
import http from '../../../../services/api/http';
import AppContext from '../../../../contexts/AppContext';
import UserContext from '../../../../contexts/UserContext/UserContext';
import PersonalitiesStep1 from './PersonalitiesStep1';
import PersonalitiesStep2 from './PersonalitiesStep2';

const PersonalitiesRegister = props => {
  const { onSubmit, updateMode, id } = props;
  const { apiHost } = useContext(AppContext);
  const { logout, token } = useContext(UserContext);
  const [loading, setLoading] = useState(updateMode);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [cache, setCache] = useState({});
  const [stepLoading, setStepLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const navigate = useNavigate();
  
  console.log(stepLoading, id, updateMode);

  const personalitiesForm = updateMode ? useForm({
    defaultValues: {
      // step 1
      virtual_artist_stage_name: '',
      virtual_artist_bio: '',
      virtual_artist_website: '',
      // step 2
      virtual_artist_main_picture_id: '',
      virtual_artist_banner_id: ''
    }
  }) : useForm({
    defaultValues: {
      // step 1
      virtual_artist_stage_name: '',
      virtual_artist_handle: '',
      virtual_artist_bio: '',
      virtual_artist_website: '',
      // step 2
      virtual_artist_main_picture_id: '',
      virtual_artist_banner_id: ''
    }
  });

  let steps;

  if(updateMode){
    steps = [
      {
        label: 'Let Us know What Is The New Personality',
        content: <PersonalitiesStep1 editor cache={cache} />
      },
      {
        label: 'Let Us Look At You',
        content: <PersonalitiesStep2 
        setStepLoading={setStepLoading} 
        cache={cache} 
        setCache={setCache}
        setUploadLoading={setUploadLoading} />
      }
    ];
  }else{
    steps = [
      {
        label: 'Let Us know What Is The New Personality',
        content: <PersonalitiesStep1 cache={cache} />
      },
      {
        label: 'Let Us Look At You',
        content: <PersonalitiesStep2 
        setStepLoading={setStepLoading} 
        cache={cache} 
        setCache={setCache}
        setUploadLoading={setUploadLoading} />
      }
    ];
  }

  const handleStep = step => async () => {
    if (await personalitiesForm.trigger()) {
      setActiveStep(step);
    }
  };

  const handleBack = () => setActiveStep(state => state - 1);

  const handleNext = async e => {
    e.stopPropagation();
    e.preventDefault();
    if (await personalitiesForm.trigger()) {
      setActiveStep(state => state + 1);
    }
  };

  const stepperSubmit = async data => {
    if (activeStep !== steps.length - 1 && (await personalitiesForm.trigger())) {
      setCompleted(state => ({ ...state, [activeStep]: true }));
      setActiveStep(activeStep + 1);
    } else {
      handleSubmit(data);
    }
  };

  /* const dataOfPersonality = async id => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/personalities?id=${id}`);

      // const  { } = get(response, 'out');
      console.log(response);
  } */

  const handleSubmit = async formData => {
    if (!formData || !token) {
      return;
    }

    let response;
    if(!updateMode){
      response = await http(apiHost, token.accessToken, token.tokenType)
        .post('/api/web/v2/personalities', formData)
        .catch(() => {});
    }else{
      response = await http(apiHost, token.accessToken, token.tokenType)
        .patch(`/api/web/v2/personalities/${id}`, formData)
        .catch(() => {});
    }

    setLoading(false);
    if (!response || !response.data) {
      return;
    }

    const { success, output } = get(response, 'data', {});
    if (!success && output) {
      let pageToBack = 0;
      Object.keys(output).forEach(field => {
        personalitiesForm.setError(field, { type: 'required', message: get(output, `${field}.0`) });
        if (['virtual_artist_stage_name',
        'virtual_artist_handle',
        'virtual_artist_bio',
        'virtual_artist_website'].includes(field)) {
          pageToBack = 0;
        } else if (
          [
            'virtual_artist_main_picture_id',
            'virtual_artist_banner_id'
          ].includes(field)
        ) {
          pageToBack = 2;
        }
      });
      setActiveStep(pageToBack);

      return;
    }

    onSubmit(formData);
    if(success){
      navigate('/joinnewrequest');
    }
    // console.log('testing form', success, activeStep, steps.length);
  };

  const loadModel = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/personalities/${id}`)
      .catch(() => {
        logout();
        window.location.reload();
      });

    // alert(get(response, 'data.output.data.0.id', {}));

    const { success, output, error } = get(response, 'data', {});
    if (!success) {
      console.warn(error);
      setLoading(false);

      return;
    }

    if(success){

      console.log(output);

      personalitiesForm.reset({
        ...pick(output, [
          // step 1
          'virtual_artist_stage_name',
          'virtual_artist_bio',
          'virtual_artist_website',
          'selected_broadcasters',
          'beneficiary_non_profit_ngo_id',
          'virtual_artist_main_picture_id',
          'virtual_artist_banner_id'
        ])
      });
  
      setCache({
         virtual_artist_banner_id: get(output, '_misc.banner.large_image'),
         virtual_artist_main_picture_id: get(output, '_misc.avatar.medium_image')
        })
      setLoading(false);
    }
  };

  /* const loginCancel = () => {
    logout();
    navigate('/');
  }; */

  useEffect(() => {
    // console.log(activeStep, steps, steps.length);
    // dataOfPersonality(id);
    if (updateMode && id) {
      loadModel();
    }
  }, []);

  useEffect(() => {
    if(uploadLoading){
      setLoading(true);
    }

    if(!uploadLoading){
      setLoading(false);
    }

}, [uploadLoading]);

  return (
    <div className="organisation common-bg">
      <div className={loading ? 'loading-overlay' : 'loading-overlay hidden'}>
        <CircularProgress color="primary" />
      </div>
      <Container className="campaign-create" maxWidth="sm">
        <Box sx={{ width: '100%', margin: '16px' }}>
          <Stepper nonLinear activeStep={activeStep} className={`py-4 ${uploadLoading ? 'v-hidden' : 'show'}`}>
            {steps.map(({ label }, index) => (
              <Step key={label} completed={completed[index]} className="hide-sm-label">
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {/* {label} */}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <div>
            <FormProvider {...personalitiesForm}>
              <Box component="form" onSubmit={personalitiesForm.handleSubmit(stepperSubmit)} autoComplete="off">
                <Grid className="d-flex flex-column" container>
                  <Typography color="primary" variant="h1" className="text-center">
                    {steps[activeStep].label}
                  </Typography>
                  {steps[activeStep].content}
                </Grid>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="primary"
                    variant="outlined"
                    className="btn-forms"
                    disabled={activeStep === 0 || uploadLoading}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {/* status === 'active' && (
                    <Button type="button" variant="outlined" className="btn-forms me-4" onClick={() => {
                      navigate('/');
                    }}>
                      Cancel
                    </Button>
                  ) */}
                  {activeStep + 1 === steps.length ? (
                    <Button type="submit" variant="outlined" className="btn-forms">
                      Submit
                    </Button>
                  ) : (
                    <Button
                      sx={{ mr: 1 }}
                      color="primary"
                      type="button"
                      onClick={handleNext}
                      variant="outlined"
                      className="btn-forms"
                    >
                      Next
                    </Button>
                  )}
                </Box>
              </Box>
            </FormProvider>
          </div>
          {/* status === 'new-personality' && (
            <div className="d-flex justify-content-center mt-4">
              <Typography type='button' component="p" color="primary" className='m-2'
                  onClick={loginCancel}>
                  <span>Want to continue later? Log out </span>
              </Typography>
            </div>
          ) */}
        </Box>
      </Container>
    </div>
  );
};

PersonalitiesRegister.defaultProps = {
  onSubmit: noop,
  onCancel: noop,
  id: null,
  updateMode: false
};

PersonalitiesRegister.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  id: PropTypes.number,
  updateMode: PropTypes.bool
};

export default PersonalitiesRegister;
